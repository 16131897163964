.newsFiltersComponent {
    text-align: center;
    width: 100%;

    form {
        border-top: 1px solid $fontColor;
        border-bottom: 1px solid $fontColor;
        
        input, select {
            display: inline-block;
            max-width: 90%;
            margin: 0.5rem 0;
            padding: 0 1rem;
            color: $fontColor;
            background-color: $bgColor;
            font-family: $sansSerif;
            font-size: 1.5rem;
            text-align: center;
            border: none;
        }
        input[name="query"] {
            width: 200px;
            border: 1px solid $fontColor;
            border-radius: 5px;
            background-color: #eef;
        }
        select {
            position: relative;
            bottom: 1px;
        }
        .submitContainer {
            display: inline-block;
        }
        input[type="submit"] {
            font-weight: 700;
            cursor: pointer;
        }
        input[type="submit"]:hover {
            color: black;
        }
        // ::placeholder {
        //     color: #444;
        //     opacity: 1;
        // }
    }
}

@media only screen and (max-width: 600px) {
    input, select {
        font-size: 1.1rem !important;
    }
}