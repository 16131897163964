.headerComponent {
    margin-bottom: 2rem;
    font-family: $serif;
    text-align: center;
    
    .earthContainer {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 10%;
        width: 80%;
    }
    .earth {
        position: relative;
        top: -30rem;
        left: 0;
        width: 40rem;
        animation-name: rotate, fadeIn;
        animation-duration: 60s, 2s;
        animation-iteration-count: infinite, 1;
        opacity: 0.9;
    }
    
    h1 {
        margin: 0;
        padding-top: 3rem;
        padding-bottom: 5rem;
        color: white;
        font-family: $headerFont;
        font-weight: 900;
        text-shadow: 2px 2px 5px black;
        font-size: 4rem;
        text-transform: uppercase;
        line-height: 3rem;
        animation-name: fadeIn;
        animation-duration: 2s;
    }
    h2 {
        
        font-size: 1.15rem;
        font-weight: 400;
        line-height: 2rem;
        animation-name: fadeIn, appearFromTop;
        animation-duration: 2s, 2s;
    }
}

@media only screen and (max-width: 600px) {
    h1 {
        padding-bottom: 2rem !important;
    }
}