@keyframes fadeIn {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes appearFromTop {
    0% {
        top: -2rem;
        position: relative;
        visibility: hidden;
    }
    100% {
        top: 0;
        position: relative;
        visibility: visible;
    }
}

@keyframes appearFromBottom {
    0% {
        bottom: -10rem;
        position: relative;
    }
    100% {
        bottom: 0;
        position: relative;
    }
}

@keyframes barFlash {
    0% {
        box-shadow: none;
        opacity: 1;
    }
    30% {
        box-shadow: 0px 0px 2px 1px $fontColor;
        opacity: 0.9;
    }
    100% {
        box-shadow: none;
        opacity: 1;
    }
}