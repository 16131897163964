.footerComponent {
    margin-top: 3rem;
    padding: 1rem 0;
    width: 100%;
    color: $fontColor;
    font-family: $serif;
    text-align: center;

    h3 {
        margin: 0;
        font-family: $headerFont;
    }

    ul {
        margin: 0;
        list-style-type: none;

        li {
            margin-left: -40px;
        }
    }
    a {
        color: $neutColor;
    }
}