@import url(https://fonts.googleapis.com/css?family=Playfair+Display+SC:900|Playfair+Display:400,700|Roboto&display=swap);
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes appearFromTop {
  0% {
    top: -2rem;
    position: relative;
    visibility: hidden; }
  100% {
    top: 0;
    position: relative;
    visibility: visible; } }

@keyframes appearFromTop {
  0% {
    top: -2rem;
    position: relative;
    visibility: hidden; }
  100% {
    top: 0;
    position: relative;
    visibility: visible; } }

@-webkit-keyframes appearFromBottom {
  0% {
    bottom: -10rem;
    position: relative; }
  100% {
    bottom: 0;
    position: relative; } }

@keyframes appearFromBottom {
  0% {
    bottom: -10rem;
    position: relative; }
  100% {
    bottom: 0;
    position: relative; } }

@-webkit-keyframes barFlash {
  0% {
    box-shadow: none;
    opacity: 1; }
  30% {
    box-shadow: 0px 0px 2px 1px #333;
    opacity: 0.9; }
  100% {
    box-shadow: none;
    opacity: 1; } }

@keyframes barFlash {
  0% {
    box-shadow: none;
    opacity: 1; }
  30% {
    box-shadow: 0px 0px 2px 1px #333;
    opacity: 0.9; }
  100% {
    box-shadow: none;
    opacity: 1; } }

body {
  margin: 0;
  background-color: snow; }

#root {
  position: relative;
  overflow-x: hidden; }

.wrapper {
  margin: 0 auto;
  width: 80%; }

.positive, .negative, .neutral {
  padding: 0 0.5rem;
  opacity: 0.8;
  color: white;
  font-weight: 700;
  border-radius: 20px;
  font-family: "Roboto", sans-serif; }

.positive {
  background-color: green; }

.negative {
  background-color: red; }

.neutral {
  background-color: blue; }

.headerComponent {
  margin-bottom: 2rem;
  font-family: "Playfair Display", serif;
  text-align: center; }
  .headerComponent .earthContainer {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 10%;
    width: 80%; }
  .headerComponent .earth {
    position: relative;
    top: -30rem;
    left: 0;
    width: 40rem;
    -webkit-animation-name: rotate, fadeIn;
            animation-name: rotate, fadeIn;
    -webkit-animation-duration: 60s, 2s;
            animation-duration: 60s, 2s;
    -webkit-animation-iteration-count: infinite, 1;
            animation-iteration-count: infinite, 1;
    opacity: 0.9; }
  .headerComponent h1 {
    margin: 0;
    padding-top: 3rem;
    padding-bottom: 5rem;
    color: white;
    font-family: "Playfair Display SC", serif;
    font-weight: 900;
    text-shadow: 2px 2px 5px black;
    font-size: 4rem;
    text-transform: uppercase;
    line-height: 3rem;
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
    -webkit-animation-duration: 2s;
            animation-duration: 2s; }
  .headerComponent h2 {
    font-size: 1.15rem;
    font-weight: 400;
    line-height: 2rem;
    -webkit-animation-name: fadeIn, appearFromTop;
            animation-name: fadeIn, appearFromTop;
    -webkit-animation-duration: 2s, 2s;
            animation-duration: 2s, 2s; }

@media only screen and (max-width: 600px) {
  h1 {
    padding-bottom: 2rem !important; } }

.newsFiltersComponent {
  text-align: center;
  width: 100%; }
  .newsFiltersComponent form {
    border-top: 1px solid #333;
    border-bottom: 1px solid #333; }
    .newsFiltersComponent form input, .newsFiltersComponent form select {
      display: inline-block;
      max-width: 90%;
      margin: 0.5rem 0;
      padding: 0 1rem;
      color: #333;
      background-color: snow;
      font-family: "Roboto", sans-serif;
      font-size: 1.5rem;
      text-align: center;
      border: none; }
    .newsFiltersComponent form input[name="query"] {
      width: 200px;
      border: 1px solid #333;
      border-radius: 5px;
      background-color: #eef; }
    .newsFiltersComponent form select {
      position: relative;
      bottom: 1px; }
    .newsFiltersComponent form .submitContainer {
      display: inline-block; }
    .newsFiltersComponent form input[type="submit"] {
      font-weight: 700;
      cursor: pointer; }
    .newsFiltersComponent form input[type="submit"]:hover {
      color: black; }

@media only screen and (max-width: 600px) {
  input, select {
    font-size: 1.1rem !important; } }

.newsFeedComponent {
  margin-top: 3rem;
  width: 42rem;
  max-width: 85%;
  font-family: "Playfair Display", serif;
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-duration: 4s;
          animation-duration: 4s; }
  .newsFeedComponent .sentimentBar {
    margin-bottom: 4rem;
    position: relative;
    border-radius: 20px;
    height: 30px;
    background: -webkit-gradient(linear, left top, right top, from(red), color-stop(30%, red), color-stop(50%, blue), color-stop(70%, green), to(green));
    background: -webkit-linear-gradient(left, red 0%, red 30%, blue 50%, green 70%, green 100%);
    background: linear-gradient(90deg, red 0%, red 30%, blue 50%, green 70%, green 100%);
    box-shadow: 0 1px 1.5px 0.5px #aaa;
    -webkit-animation-name: barFlash;
            animation-name: barFlash;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
    .newsFeedComponent .sentimentBar .avgSentiment {
      position: absolute;
      z-index: 99;
      left: 50%;
      top: -7px;
      margin-left: -10px;
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 20px solid #333; }
    .newsFeedComponent .sentimentBar p {
      position: relative;
      top: 4px;
      margin: 0;
      font-family: "Roboto", sans-serif;
      text-align: center;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 5px;
      color: white;
      opacity: 0.7; }
  .newsFeedComponent .circularProgress {
    margin: 1rem 0;
    text-align: center; }
  .newsFeedComponent .articleUrl {
    display: block;
    color: black;
    text-decoration: none;
    cursor: pointer; }
  .newsFeedComponent .articleUrl:hover, .newsFeedComponent .articleUrl:focus {
    bottom: 0.1rem;
    box-shadow: 0 1px 1.5px 0.5px #aaa; }
  .newsFeedComponent .articleContainer {
    padding: 1rem 0;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 0.7rem;
    border-bottom: 0.1px solid #333;
    -webkit-animation-name: fadeIn, appearFromTop;
            animation-name: fadeIn, appearFromTop;
    -webkit-animation-duration: 1s, 1s;
            animation-duration: 1s, 1s; }
    .newsFeedComponent .articleContainer .articleImage {
      margin: auto 0;
      margin-left: 0.8rem; }
    .newsFeedComponent .articleContainer .articleImage img {
      width: 100%; }
    .newsFeedComponent .articleContainer h3 {
      line-height: 1.6rem; }
    .newsFeedComponent .articleContainer h4 {
      font-weight: 400; }

@media only screen and (max-width: 700px) {
  .articleContainer {
    display: block !important;
    padding-top: 2rem !important; }
    .articleContainer .articleImage {
      margin: 0 !important; }
    .articleContainer .articleInfo, .articleContainer .articleSentiment {
      width: 90%;
      margin: 0 auto; } }

.footerComponent {
  margin-top: 3rem;
  padding: 1rem 0;
  width: 100%;
  color: #333;
  font-family: "Playfair Display", serif;
  text-align: center; }
  .footerComponent h3 {
    margin: 0;
    font-family: "Playfair Display SC", serif; }
  .footerComponent ul {
    margin: 0;
    list-style-type: none; }
    .footerComponent ul li {
      margin-left: -40px; }
  .footerComponent a {
    color: blue; }

