// import styling resources
@import "./fonts.scss";
@import "./variables.scss";
@import "./animations.scss";

body {
    margin: 0;
    background-color: $bgColor;
}

#root {
    position: relative;
    overflow-x: hidden;
}

.wrapper {
    margin: 0 auto;
    width: 80%;
}

.positive, .negative, .neutral {
    padding: 0 0.5rem;
    opacity: 0.8;
    color: white;
    font-weight: 700;
    border-radius: 20px;
    font-family: $sansSerif;
}
.positive { background-color: $posColor; }
.negative { background-color: $negColor; }
.neutral { background-color: $neutColor; }

// load in partials
@import "./partials/Header.scss";
@import "./partials/NewsFilters.scss";
@import "./partials/NewsFeed.scss";
@import "./partials/Footer.scss";