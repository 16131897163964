.newsFeedComponent {
    margin-top: 3rem;
    width: 42rem;
    max-width: 85%;
    font-family: $serif;
    animation-name: fadeIn;
    animation-duration: 4s;

    .sentimentBar {
        margin-bottom: 4rem;
        position: relative;
        border-radius: 20px;
        height: 30px;
        background: linear-gradient(90deg, $negColor 0%, $negColor 30%, $neutColor 50%, $posColor 70%, $posColor 100%);
        box-shadow: $boxShadow;
        animation-name: barFlash;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in;

        .avgSentiment {
            position: absolute;
            z-index: 99;
            left: 50%;
            top: -7px;
            margin-left: -10px;
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-top: 20px solid $fontColor;
        }

        p {
            position: relative;
            top: 4px;
            margin: 0;
            font-family: $sansSerif;
            text-align: center;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 5px;
            color: white;
            opacity: 0.7;
        }
    }

    .circularProgress {
        margin: 1rem 0;
        text-align: center;
    }
    
    .articleUrl {
        display: block;
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
    .articleUrl:hover, .articleUrl:focus {
        bottom: 0.1rem;
        box-shadow: $boxShadow;
    }

    .articleContainer {
        padding: 1rem 0;
        position: relative;
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        grid-gap: 0.7rem;
        border-bottom: 0.1px solid $fontColor;
        animation-name: fadeIn, appearFromTop;
        animation-duration: 1s, 1s;
        
        .articleImage {
            margin: auto 0;
            margin-left: 0.8rem;
        }
        
        .articleImage img {
            width: 100%;
        }

        h3 {
            line-height: 1.6rem;
        }

        h4 {
            font-weight: 400;
        }

    }
}

@media only screen and (max-width: 700px) {
    .articleContainer {
        display: block !important;
        padding-top: 2rem !important;

        .articleImage {
            margin: 0 !important;
        }

        .articleInfo, .articleSentiment {
            width: 90%;
            margin: 0 auto;
        }
    }
}